<script setup>
	import { storeToRefs } from 'pinia';
	import { useWindowStore } from '@/stores/window';

	const props = defineProps({
		items: {
			type: Object,
			required: false,
		},
		bullets: {
			type: Boolean,
			required: false,
			default: true,
		},
		single: {
			type: Boolean,
			required: false,
			default: false,
		},
		bulletStyle: {
			type: String,
			required: false,
			default: 'primary',
			validator: (value) => {
				return ['primary', 'secondary'].includes(value);
			},
		},
		threshold: {
			type: [String, Number],
			required: false,
			default: 0.25,
		},
		arrowPrefix: {
			type: String,
			required: false,
			default: 'chevron',
		},
	});

	const { items, bullets, bulletStyle, single, threshold } = toRefs(props);

	const { innerWidth } = storeToRefs(useWindowStore());

	// Variables
	const active = ref(0);

	const itemsRef = ref([]);
	const itemHeight = ref('auto');
	const itemsWrapperRef = ref(null);
	const scrollWidth = ref({});

	const { $bus } = useNuxtApp();
	const observer = ref(null);

	const debounceTimeout = ref(null);
	const debounce = (index) => {
		clearTimeout(debounceTimeout.value);
		// 60fps is 1 frame every 16ms
		const frame = 16;
		debounceTimeout.value = setTimeout(() => {
			setItemHeight(index);
		}, frame * 4);
	};

	const setUpIntersectionObserver = () => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: parseFloat(threshold.value),
		};
		observer.value = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					active.value = parseInt(entry.target.dataset.index);
				}
			});
		}, options);

		itemsRef.value.forEach((item) => {
			observer.value.observe(item);
		});
	};

	const setScrollWidth = (pass = 0) => {
		if (pass > 10) return;
		const width = itemsWrapperRef?.value?.getBoundingClientRect()?.width || 0;
		if (width === 0) return setTimeout(() => setScrollWidth(pass + 1), 100);
		scrollWidth.value.scroll = width;

		return scrollWidth.value.scroll;
	};

	const updateActive = (index) => {
		let value = parseInt(index);
		// Make an infinite loop for arrows
		if (index < 0) value = items.value.length - 1;
		else if (index >= items.value.length) value = 0;
		// Get the scroll width
		const scrollTo = scrollWidth.value.scroll * value;
		itemsWrapperRef.value.scrollTo({
			left: scrollTo,
			behavior: 'smooth',
		});

		// Update Active
		active.value = value;

		// NOTE: Since we are listing to the scroll event,
		// we don't need to update the height here.
		// See debounce function above.
	};

	const setItemHeight = (index, depth = 0) => {
		let value = parseInt(index);
		// NOTE: For some reason, itemsRef was reversing the index order.
		// Needed to create a new object that properly mapped the item on the DOM with the index in the object.
		let arrayByDataId = new Object();
		itemsRef?.value?.map((index) => {
			const dataIndex = index.dataset.index;
			arrayByDataId[dataIndex] = index;
		});
		const item = arrayByDataId[value];
		if (depth > 100) return;
		if (!item)
			return setTimeout(() => {
				setItemHeight(value, depth + 1), 100;
			});
		const currentItemHeight = item.offsetHeight;

		if (currentItemHeight === 0)
			return setTimeout(() => {
				setItemHeight(value, depth + 1), 100;
			});

		itemHeight.value = `${currentItemHeight}px`;
	};

	const isSingle = computed(() => {
		return items?.value?.length <= 1;
	});

	const debounceTimer = ref(null);
	const checkHeight = () => {
		clearTimeout(debounceTimer.value);
		debounceTimer.value = setTimeout(() => {
			setItemHeight(active.value);
		}, 500);
	};

	// Lifecycle
	onMounted(() => {
		checkHeight();
		const resizeObserver = new ResizeObserver(() => {
			checkHeight();
		});

		resizeObserver.observe(itemsWrapperRef.value);
		resizeObserver.observe(itemsRef.value[0]);

		setUpIntersectionObserver();
		setScrollWidth();
		setTimeout(() => {
			setItemHeight(0);
		}, 250);
		$bus.on('resize', ($event) => {
			setScrollWidth();
		});
		itemsWrapperRef.value.addEventListener('scroll', () => debounce(active.value));
	});

	onBeforeUnmount(() => {
		if (process.client) {
			itemsRef.value = [];
			itemsWrapperRef.value = null;
			scrollWidth.value = {};
			if (observer.value) {
				observer.value.disconnect();
			}
			$bus.off('resize');
			if (itemsWrapperRef.value) {
				itemsWrapperRef.value.removeEventListener('scroll', () => debounce(active.value));
			}
		}
	});

	watch(innerWidth, () => {
		setScrollWidth();
	});
</script>

<template>
	<section class="mess-carousel" :class="isSingle ? 'single' : ''" :style="`--height: ${itemHeight};`">
		<ul class="carousel-inner" ref="itemsWrapperRef">
			<li
				v-for="(item, index) in items"
				:key="item"
				:class="`index-${index}`"
				class="carousel-item"
				ref="itemsRef"
				:data-index="index"
			>
				<slot name="item" v-bind="[item, index]" />
			</li>
		</ul>
		<div v-if="!isSingle" class="scroll-container">
			<button :class="['arrow left', bulletStyle]" @click="updateActive(active - 1)">
				<ProjectSvg :type="`${arrowPrefix}Left`" />
			</button>
			<ul v-if="bullets && !isSingle" :class="['bullets', bulletStyle]">
				<li v-for="bulletIndex in items.length" :key="`bullet-${bulletIndex}`" :class="`bullet-${bulletIndex}`">
					<button
						class="bullet"
						:class="{ active: active === bulletIndex - 1 }"
						@click="updateActive(bulletIndex - 1)"
					/>
				</li>
			</ul>
			<button :class="['arrow right', bulletStyle]" @click="updateActive(active + 1)">
				<ProjectSvg :type="`${arrowPrefix}Right`" />
			</button>
		</div>
	</section>
</template>

<style lang="scss">
	.mess-carousel {
		position: relative;
		width: calc(100% + 2rem);
		margin-left: -1rem;

		.carousel-inner {
			display: flex;
			flex-wrap: nowrap;
			overflow-y: hidden;
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;
			scroll-snap-type: x mandatory;
			scroll-behavior: smooth;
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: none;
			scrollbar-width: none;
			align-items: flex-start;
			height: var(--height);
			transition: height 350ms ease;

			&::-webkit-scrollbar {
				display: none;
			}

			.carousel-item {
				flex: 0 0 100%;

				scroll-snap-align: start;
				padding: 0 1rem;
			}
		}

		.scroll-container {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			gap: 1rem;
			position: relative;
			// margin-top: 1rem;
			* {
				z-index: 1;
			}
		}

		.arrows {
			position: relative;
			display: flex;
			gap: 5rem;
			margin-bottom: 0.5rem;
			.arrow {
				height: 2rem;
				width: 1rem;
				color: black;
				position: relative;
				svg {
					position: absolute;
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
				}
			}
		}

		.bullets {
			display: flex;
			gap: 0.5em;
			flex-wrap: wrap;
			max-width: calc(var(--vw) * 80);

			&.primary {
				.bullet {
					display: inline-block;
					width: 5.5rem;
					height: 2px;
					background-color: #dedede;
					transition: 200ms ease-in-out;
					cursor: pointer;

					&:hover,
					&.active {
						background-color: black;
					}
				}
			}
			&.secondary {
				.bullet {
					display: inline-block;
					width: 16px;
					height: 16px;
					margin: 0 6px;
					border-radius: 50%;
					background-color: var(--gray-100);
					border: 1px solid var(--red);
					transition: background-color 0.25s ease-in-out;
					cursor: pointer;

					@media (min-width: $mobile) {
						margin: 0 15px;
					}

					&:hover,
					&.active {
						background-color: var(--red);
						border: none;
					}
				}
			}
		}

		&.single {
			.arrow {
				display: none;
			}
		}
	}

	@media (max-width: 768px) {
		.carousel {
			overflow: hidden;
			.arrow {
				--offset: calc(100% + 0.333em);
			}
			.bullets.primary {
				.bullet {
					width: 2rem;
				}
			}
		}
	}
</style>
